import React from "react";

import GitHubLogo from "../../assets/github.svg";
import LinkedInLogo from "../../assets/linkedin.svg";

import "./contact.styles.scss";

const Contact = () => (
  <>
    <div className="logo-container">
      <a href="https://github.com/NemanjaMilenkovic">
        <img
          className="github-logo social-media-logo"
          src={GitHubLogo}
          alt="GitHub logo"
        ></img>
      </a>
      <a href="https://www.linkedin.com/in/nemanjamilenkovic/">
        <img
          className="linkedin-logo social-media-logo"
          src={LinkedInLogo}
          alt="LinkedIn logo"
        ></img>
      </a>
    </div>
    <div className="about-me">
      <p> Hello there!</p>
      <p>
        I’m Nemanja, a software engineer with experience in JavaScript, React,
        React Native and Java, to name a few.
      </p>
      <p>
        <strong>In a nutshell: </strong>
        <br />I build software with absolute pleasure.
      </p>
      <p>
        And I'm able to think of the bigger picture so the others can enjoy my
        work too.
      </p>
      <p>
        On top of that, I can also wrestle data well. And pick up new skills -
        quickly and willingly.
      </p>

      <p>
        Now, if found your way here and read through up to this point - I'd love
        to hear how and I'd love to hear why :)
      </p>
      <a
        className="mailto"
        href="mailto:nel.milen@gmail.com?subject=I'd like to tell you what brought me to the MLNKV store"
      >
        nel.milen@gmail.com
      </a>
    </div>
  </>
);

export default Contact;
