import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { auth } from "../../firebase/firebase.utils";

import { ReactComponent as Logo } from "../../assets/mlnkv-logo.svg";
import CartIcon from "../cart-icon/cart-icon.component";
import CardDropdown from "../cart-dropdown/cart-dropdown.component";
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";

import "./header.styles.scss";

const Header = ({ currentUser, hidden }) => (
  <div className="header">
    <Link className="logo-container" to="/">
      <Logo className="logo" />
    </Link>
    <div className="options">
      <Link className="option" to="shop/hats">
        SHOP
      </Link>
      <Link className="option" to="/contact">
        CONTACT
      </Link>
      {currentUser ? (
        <>
          <div className="option" onClick={() => auth.signOut()}>
            SIGN OUT
          </div>
          <div className="photo-container">
            {currentUser.photoURL ? (
              <img
                className="account-photo"
                src={`${currentUser.photoURL}`}
                alt="Account profile"
              ></img>
            ) : null}
          </div>
          <CartIcon className="cart" />
        </>
      ) : (
        <>
          <Link className="option" to="/signin">
            SIGN IN
          </Link>
          <div className="empty-container"></div>
        </>
      )}
    </div>
    {hidden ? null : <CardDropdown />}
  </div>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden,
});

export default connect(mapStateToProps)(Header);
