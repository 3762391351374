import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCEvTSPDfB4k9NpYvGpWWPZXw5mWdxXlmo",
  authDomain: "mlnkv-store.firebaseapp.com",
  databaseURL: "https://mlnkv-store.firebaseio.com",
  projectId: "mlnkv-store",
  storageBucket: "mlnkv-store.appspot.com",
  messagingSenderId: "700129038409",
  appId: "1:700129038409:web:37ac9e21198d82f6326f98",
  measurementId: "G-BR3CE9DN2H",
};

export const createUserProfileDocument = async (
  userAuthObject,
  additionalData
) => {
  if (!userAuthObject) return;

  const userRef = firestore.doc(`users/${userAuthObject.uid}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email, photoURL } = userAuthObject;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        photoURL,
        ...additionalData,
      });
    } catch (err) {
      console.log("error creating user", err.message);
    }
  }
  return userRef;
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
